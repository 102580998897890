<template>
<div>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Artikelstatistik</h4>
                            <p>Liste aller Artikelumsätze</p>
                        </div>
                        <div class="card-tools">
                          <button class="btn btn-warning" @click="exportStornos()" v-b-popover.hover.bottom="'Die Statistik bezieht sich auf die unten genommenen Einstellungen'">
                            Statistik Exportieren
                          </button>
                        </div>
                    </div>
                    <div class="card-body">
                      <div class="card-tools">
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Firma</label>
                              <div class="col-md-9">
                                <select class="form-control form-control-sm" v-model="params.company">
                                  <option :value="null">-- Filter Firma --</option>
                                  <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                                </select>
                              </div>   
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Kundengruppe</label>
                              <div class="col-md-9">
                                <select class="form-control form-control-sm" v-model="params.group">
                                  <option :value="null">-- Filter Kundengruppe --</option>
                                  <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.description }}</option>
                                </select>
                              </div>   
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Hersteller</label>
                              <div class="col-md-9">
                                <select class="form-control form-control-sm" v-model="params.producer_id">
                                  <option :value="null">-- Filter Hersteller --</option>
                                  <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{producer.number}} - {{ producer.name }}</option>
                                </select>
                              </div>   
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Suche</label>
                              <div class="col-md-9">

                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                  <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="reload" v-model="params.search" />
                                  <button v-if="this.params.search != ''" type="button" class="btn btn-danger btn-sm" @click="resetSearch">
                                      <i class="fas fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6>Zeitraum:</h6>
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Von:</label>
                              <div class="col-md-9">
                                <input type="date" class="form-control form-control-sm" v-model="params.start_date" />
                              </div>   
                            </div>
                          </div>

                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Bis:</label>
                              <div class="col-md-9">
                                <input type="date" class="form-control form-control-sm" v-model="params.stop_date" />
                              </div>  
                            </div>
                          </div>
                        </div>
                        <h6>Vergleichszeitraum:</h6>
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Von:</label>
                              <div class="col-md-9">
                                <input type="date" class="form-control form-control-sm" v-model="params.start_compare_date" />
                              </div>   
                            </div>
                          </div>

                          <div class="col-md-5">
                            <div class="form-group row">
                              <label class="col-md-3 col-form-label">Bis:</label>
                              <div class="col-md-9">
                                <input type="date" class="form-control form-control-sm" v-model="params.stop_compare_date" />
                              </div>  
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group row">
                              <div class="col-md-9">
                                <button class="btn btn-primary btn-sm " @click="reload">
                                  <i class="fas fa-sync"></i>
                                </button>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <b-row class="mt-3">
                          <b-col>
                            <div class="table-responsive">
                              <table class="table table-hover table-sm">
                                  <thead>
                                      <th>
                                          <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th>
                                          <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th><a href="#" @click.prevent="changeSort('sales')">Umsatz</a>
                                          <span v-if="this.params.sort_field == 'sales' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'sales' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <th><a href="#" @click.prevent="changeSort('qty')">Stückzahl</a>
                                          <span v-if="this.params.sort_field == 'qty' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                          <span v-if="this.params.sort_field == 'qty' && this.params.sort_direction == 'desc'" >&darr;</span>
                                      </th>
                                      <template  v-if="params.start_compare_date != '' && params.stop_compare_date != ''">
                                        <th>
                                            Umsatz (VGZ)
                                            <!-- <a href="#" @click.prevent="changeSort('sales_compare')">Umsatz (Vergleichszeitraum)</a>
                                            <span v-if="this.params.sort_field == 'sales_compare' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'sales_compare' && this.params.sort_direction == 'desc'" >&darr;</span> -->
                                        </th>
                                        <th>
                                            Stückzahl (VGZ)
                                            <!-- <a href="#" @click.prevent="changeSort('sales_amount_compare')">Stückzahl (Vergleichszeitraum)</a>
                                            <span v-if="this.params.sort_field == 'sales_amount_compare' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'sales_amount_compare' && this.params.sort_direction == 'desc'" >&darr;</span> -->
                                        </th>
                                      </template>
                                  </thead>
                                  <tbody>
                                      <tr v-for="(article) in articles.data" :key="article.id">
                                        <td>{{article.ordernumber}}</td>
                                        <td>{{article.article_name}}</td>
                                        <td>{{article.producers_name}}</td>
                                        <td>{{article.sales_total | toCurrency}}</td>
                                        <td>{{article.qty}}</td>
                                        <template v-if="params.start_compare_date != '' && params.stop_compare_date != ''">
                                          <td>
                                            {{article.sales_total_compare | toCurrency}}
                                          </td>
                                          <td>
                                            {{article.qty_compare}}
                                          </td>
                                        </template>
                                      </tr>
                                      <tr style="text-align: left; color: red;">
                                        <td colspan="3" style="text-align: right; color: red;">
                                          <b>Gesamt:</b>
                                        </td>
                                        <td>{{sum.umsatz | toCurrency}}</td>
                                        <td>{{sum.qty}}</td>
                                        <td>{{sum.umsatz_compare | toCurrency}}</td>
                                        <td>
                                          {{sum.qty_compare}}
                                        </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                          </b-col>
                      </b-row>
                    </div>
                    <div class="clearfix align-middle card-footer">
                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Artikel</option>
                            <option value="50">50 Artikel</option>
                            <option value="75">75 Artikel</option>
                            <option value="100">100 Artikel</option>
                            <option value="125">125 Artikel</option>
                            <option value="150">150 Artikel</option>
                        </select>
                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
    <aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
        </div>
    </aside>
</div>
</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import moment from 'moment'
// import {Tree, Fold} from 'he-tree-vue'

export default {
  name: "ArticleStatistic",
  title: "Artikelstatistik",
  data() {
    return {
        // Our data object that holds the Laravel paginator data
        articles: {},
        producers: {},
        groups: [],
        companies: [],
        sum: {
          umsatz: 0,
          umsatz_compare: 0,
          qty: 0,
          qty_compare: 0,
        },
        compare: false,
        params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                producer_id: '',
                per_page: 25,
                start_date: '',
                stop_date: '',
                start_compare_date: '',
                stop_compare_date: '',
                company: null,
                group: null,
                // search: ''
            },
        search: '',
        page: 1,
    }
	},

  watch: {
        'params.company': {
            handler () {
                this.params.group = null;
                this.generateCustomerGroups();
            },
            deep: true
        },
    },


	mounted() {
    this.params.start_date = moment().subtract(1, 'month').format('Y-MM-DD');
    this.params.stop_date =  moment().format('Y-MM-DD');
    this.params.start_compare_date = moment().subtract(1, 'month').subtract(1, 'year').format('Y-MM-DD');
    this.params.stop_compare_date =  moment().subtract(1, 'year').format('Y-MM-DD');
    this.getAllArticles();
    this.loadProducer();
    this.getCompanies();
    this.params.producer_id = null;
	},

	methods: {
        generateCustomerGroups(){
            var company = this.companies.find(item => item.id == this.params.company);
            if(company == undefined){
                this.groups = [];
            }
            else{
                this.groups = company.groups
            }
            
        },
        getCompanies() {
          this.axios
            .get('/companies', { 
                    params: {
                        groups: true
                    }
            })
            .then((response) => {
              this.companies = response.data.data;
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Da ist wohl etwas schief gelaufen'
              })
            })
        },
        calculateSum()
        {
          this.sum.qty = 0;
          this.sum.umsatz = 0;
          this.sum.qty_compare = 0;
          this.sum.umsatz_compare = 0;

          this.articles.data.forEach(element => {
            this.sum.qty += element.qty;
            this.sum.umsatz += element.sales_total;
            this.sum.qty_compare += element.qty_compare;
            this.sum.umsatz_compare += element.sales_total_compare;
          });
        },
        resetSearch(){
            this.params.search = '';
            this.reload();
        },
        reload()
        {
          this.getAllArticles();
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
            this.getAllArticles();
        },

        loadProducer()
        {
          this.axios
            .get("/producers", {
              params: {
                  per_page: 1000
              }
            })
            .then((response) => {
              this.producers = response.data.data;
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
              })
            })
        },

        exportStornos()
        {
          this.$Progress.start();
          this.axios
            .get("/statistics/export", {
              params: {
                  search: this.search,
                  ...this.params
              },
              responseType: 'blob',
            })
            .then((response) => {
              this.$Progress.finish();
              const url = window.URL.createObjectURL(
                  new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
              );

              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download",  'statistik.xlsx');
              document.body.appendChild(link);

              link.click();
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Beim Exportieren ist etwas schief gelaufen'
              })
              this.$Progress.fail();
            });
        },
        getAllArticles(page) {
          if(page == undefined)
          {
              page = this.page 
          }
          this.axios
              .get("/statistics/articles", {
                  params: {
                      page: page,
                      search: this.search,
                      ...this.params
                  }
              })
              .then((response) => {
                  this.articles = response.data;
                  this.calculateSum();
              })
              .catch(() => {
                  this.$swal({
                      icon: "error",
                      title: "Oops...",
                      text: "Da ist etwas schief gelaufen",
                  });
              });
      },
	}
}
</script>